




























import { Vue, Component, Prop } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import { Form } from "element-ui";
import FormItemBox from "@/components/sign/FormItemBox.vue";
import signjs from "@/components/sign/index";
import { subProjectBasic } from "@/components/sign/index";

@Component({
    components: {
        "el-form": Form,
        "al-form-item": FormItemBox
    }
})
export default class ProjectBasicForm extends Vue {
    @Prop({ type: String, required: true, default: () => ({}) })
    childName!: string; //表单数据

    isshow: boolean = false;

    registItem_class_basic = "regist";
    get registForm() {
        let opt = [
            {
                ref: "name",
                formkey: "name",
                type: "input",
                classname: "regist-name",
                label: "案例名称",
                placeholder: "请输入案例名称"
            },
            {
                ref: "contact",
                formkey: "contact",
                type: "input",
                classname: `${this.registItem_class_basic}-contact`,
                label: this.$t("sign.contactLabel"),
                placeholder: this.$t("sign.contactPlaceholder")
            },
            {
                ref: "position",
                formkey: "position",
                type: "input",
                classname: `${this.registItem_class_basic}-position`,
                label: this.$t("sign.contact_position_label"),
                placeholder: this.$t("sign.contact_position_placeholder")
            },
            {
                ref: "phone",
                formkey: "phone",
                type: "input",
                classname: `${this.registItem_class_basic}-phone`,
                label: this.$t("sign.contact_mobile_label"),
                placeholder: this.$t("sign.contact_mobile_placeholder")
            },
            {
                ref: "email",
                formkey: "email",
                type: "input",
                classname: `${this.registItem_class_basic}-email`,
                label: this.$t("sign.contact_email_label"),
                placeholder: this.$t("sign.contact_email_placeholder")
            },
            {
                ref: "type",
                formkey: "type",
                type: "radio",
                classname: `${this.registItem_class_basic}-type`,
                label: "机构类型",
                placeholder: "请选择机构类型",
                apikey: "getTypeListt",
                apiResKey: "data"
            },
            {
                ref: "basic_info",
                formkey: "basic_info",
                type: "editor",
                maxlength: "350",
                rows: "4",
                classname: `${this.registItem_class_basic}-basic_info`,
                label: "基本信息",
                placeholder: "请填写基本信息",
                example:"（200字左右，包括案例基本情况介绍、机构所属类型或技术领域、目前进展等）"
            },        

            {
                ref: "introduction",
                formkey: "introduction",
                type: "editor",
                maxlength: "350",
                rows: "4",
                classname: `${this.registItem_class_basic}-introduction`,
                label: this.$t("sign.contact_compIntro_label"),
                placeholder: this.$t("sign.contact_compIntro_placeholder"),
                example:"（500字左右，包括机构案例的成立背景、组织架构、运营模式、发展目标等，或项目案例的研发背景、合作模式、交易流程及相关情况）"
            },
            {
                ref: "advantage",
                formkey: "advantage",
                type: "editor",
                maxlength: "350",
                rows: "4",
                classname: `${this.registItem_class_basic}-advantage`,
                label: "特征优势",
                placeholder: "请填写特征优势",
                example:"（800字左右，包括跨境合作实践的特色模式、技术项目的管理与运营、对产业化水平与经济发展带来的成效等）"
            },
            
        ];
        return opt;
    }

    /* 表单数据 */
    registFormData = {
        name:"",
        contact: "",
        email: "",
        phone: "",
        type:"",
        position: "",
        basic_info:"",

        company: "",
        introduction: "",
        advantage:""
        
    };

    /* 邮箱格式验证 */
    private validateEmailRule(rule: any, value: any, callback: any) {
        let emailreg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
        if (value === "") {
            callback(
                new Error(this.$t("sign.contact_email_rule_notnull") as string)
            );
        }
        //  else if (!emailreg.test(value)) {
        //     callback(
        //         new Error(this.$t("sign.contact_email_rule_format") as string)
        //     );
        // }
        else {
            callback();
        }
    }

    /* 手机格式验证 */
    private validatePhoneRule(rule: any, value: any, callback: any) {
        let phonereg = /^[1][3,4,5,7,8][0-9]{9}$/;
        if (value === "") {
            callback(new Error("电话号码不能为空"));
        } else if (!phonereg.test(value)) {
            callback(new Error("电话号码格式错误"));
        } else {
            callback();
        }
    }

    /* 注册表单验证规则 */
    get registFormRules() {
        let opt = {
            name: [
                {
                    required: true,
                    message: "案例名称不能为空",
                    trigger: "blur"
                }
            ],
            contact: [
                {
                    required: true,
                    message: this.$t("sign.contact_rule_notnull"),
                    trigger: "blur"
                }
            ],
            position: [
                {
                    required: true,
                    message: this.$t("sign.contact_position_rule_notnull"),
                    trigger: "blur"
                }
            ],
            phone: [
                {
                    required: true,
                    message: this.$t("sign.contact_mobile_rule_notnull"),
                    trigger: "blur"
                }
            ],
            email: [
                {
                    required: true,
                    message: this.$t("sign.contact_email_rule_notnull"),
                    trigger: "blur"
                },
                { validator: this.validateEmailRule, trigger: "blur" }
            ],
            type: [
                {
                    required: true,
                    message: "机构类型为必选项",
                    trigger: "blur"
                }
            ],
            basic_info: [
                {
                    required: true,
                    message: "基本信息为必填项",
                    trigger: "blur"
                }
            ],
            
        
            introduction: [
                {
                    required: true,
                    message: this.$t("sign.contact_compIntro_rule_notnull"),
                    trigger: "change"
                }
            ],
            advantage: [
                {
                    required: true,
                    message: "特征优势为必填项",
                    trigger: "change"
                }
            ]
        };
        return opt;
    }

    /*
     *  获取表单数据
     *  params有pid则请求表单数据，渲染到表单
     *  验证过pid后才会加载表单
     */
    getformData() {
        if (this.$route.params.pid) {
            this.$axios({
                url: `http://ittn_data.ittn.com.cn/api/besttt/case/${this.$route.params.pid}`,
                method: "get",
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem(
                        "api_token"
                    )}`,
                    lang: this.$i18n.locale
                }
            }).then((res: any) => {
                this.registFormData = res.data.data;
                this.isshow = true;
            });
        } else {
            this.isshow = true;
        }
    }

    //提交表单
    sub(formName: string, callback: any, isloading?: any) {
        signjs.validateForm(formName, this.$refs).then(res => {
            // console.log(this.registFormData);
            if (res) {
                isloading(); //打开loading遮罩层
                this.setFormData(this.registFormData).then((res: any) => {
                    subProjectBasic(
                        res,
                        this.$route.params.pid
                            ? this.$route.params.pid
                            : undefined
                    ).then((res: any) => {
                        this.$notify({
                            title: res.status === 0 ? "成功" : "失败",
                            message: res.msg,
                            type: res.status === 0 ? "success" : "error"
                        });
                        if (callback) {
                            callback(res.status, res.data);
                        } else {
                            console.error("Regist:No Callback");
                        }
                    });
                });
            }
        });
    }

    //重置表单数据
    resetForm(formName: string) {
        //@ts-ignore
        signjs.resetForm(formName, this.$refs);
    }

    /* 提交前设置提交数据 */
    async setFormData(data: any) {
        const subdata = JSON.parse(JSON.stringify(data));
        if (sessionStorage.getItem("channel_id")) {
            subdata.channel_id = sessionStorage.getItem("channel_id");
        }
        return subdata;
    }

    // 表单单项验证
    validItem(formname: string, formItem: string | object, callback: any) {
        signjs
            .validateFormItem(formname, formItem, this.$refs)
            .then((res: any) => {
                callback(res);
            });
    }

    test(formname?: string) {
        // this.registFormData = {
        //     contact: "赵韧锋",
        //     email: "zhaozinan@ittn.com.cn",
        //     mobile: "17715198888",
        //     company: "ITTN",
        //     position: "前端工程师",
        //     c_introduction:
        //         "<p><img src='https://ittn.oss-cn-beijing.aliyuncs.com/ittn_projects/images/mCO3hc32ok2iUsj3HjUtx06oBAcTZPcdvUABe6bv.png' style='max-width:100%;'/><br/></p><p>阿萨大大大是撒大苏打</p>",
        //     website: "http://ittn.com.cn",
        //     beizhu: ""
        // };
    }

    mounted() {
        this.getformData();
    }
}
